import React, { useState } from 'react';
import './App.css';

function App() {
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [annualExpenses, setAnnualExpenses] = useState('');
  const [currentSavings, setCurrentSavings] = useState('');
  const [annualReturn, setAnnualReturn] = useState('');
  const [result, setResult] = useState(null);

  const calculateFIRE = () => {
    const yearsToRetirement = retirementAge - currentAge;
    const fireNumber = annualExpenses * 25;
    const futureValueOfSavings = currentSavings * Math.pow(1 + annualReturn / 100, yearsToRetirement);
    const additionalSavingsNeeded = fireNumber - futureValueOfSavings;

    setResult({
      fireNumber: fireNumber.toFixed(2),
      additionalSavingsNeeded: additionalSavingsNeeded.toFixed(2)
    });
  };

  return (
    <div className="App">
      <h1>FIRE Calculator</h1>
      <div>
        <label>Current Age: </label>
        <input type="number" value={currentAge} onChange={(e) => setCurrentAge(e.target.value)} />
      </div>
      <div>
        <label>Retirement Age: </label>
        <input type="number" value={retirementAge} onChange={(e) => setRetirementAge(e.target.value)} />
      </div>
      <div>
        <label>Annual Expenses: </label>
        <input type="number" value={annualExpenses} onChange={(e) => setAnnualExpenses(e.target.value)} />
      </div>
      <div>
        <label>Current Savings: </label>
        <input type="number" value={currentSavings} onChange={(e) => setCurrentSavings(e.target.value)} />
      </div>
      <div>
        <label>Expected Annual Return (%): </label>
        <input type="number" value={annualReturn} onChange={(e) => setAnnualReturn(e.target.value)} />
      </div>
      <button onClick={calculateFIRE}>Calculate</button>
      {result && (
        <div>
          <h2>Results:</h2>
          <p>FIRE Number: ${result.fireNumber}</p>
          <p>Additional Savings Needed: ${result.additionalSavingsNeeded}</p>
        </div>
      )}
    </div>
  );
}

export default App;
